import { util } from '@/utils/';
import http from '../request/index';

export default {

  // 发送手机验证码
  sendSMSCode(data) {
    return http.post('/app-api/system/auth/send-sms-code', data);
  },
  //获得个人信息
  getUserInfo(data){
    const url = util.urlAddParams('/app-api/customer/userinfo/get', data);
    return http.get(url);
  },
  //更新用户信息
  updateUserInfo(data){
    return http.put('/app-api/customer/userinfo/update', data);
  },

  uploadImg(data){
    return http.post('/app-api/infra/file/upload', data);
  },

  // uploadAvat(data){
  //   return http.post('/app-api/infra/file/upload', data);
  // }
};
