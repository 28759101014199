import { util } from '@/utils/';
import http from '../request/index';

export default {
	getDeptList(data) {
		const url = util.urlAddParams('/app-api/system/dept/rootPage', data);
		return http.get(url);
	},

	getLeafDeptList(data) {
		const url = util.urlAddParams('/app-api/system/dept/leafPage', data);
		return http.get(url);
	},

	getMpAuthUrl(data) {
		const url = util.urlAddParams('/app-api/system/auth/getMpAuthUrl', data);
		return http.get(url);
	},

	getConsultantByDeptId(data) {
		const url = util.urlAddParams('/app-api/system/user/listByDeptId', data);
		return http.get(url);
	},

	// 绑定(咨询师/店长/医生） Mobile 与 OpenId
	bindUserOpenId(data) {
		return http.post('/app-api/system/auth/bindUserUnionid', data);
	},

	// 获取医生分页数据
	getDoctorList(data) {
		const url = util.urlAddParams('/app-api/system/user/doctorPage', data);
		return http.get(url);
	},

	// 微信公众号授权token
	wxMpAuthToken(data) {
		return http.post('/app-api/system/auth/wxMpAuthToken', data);
	},

	//获取用户协议和隐私协议
	getContent(data) {
		const url = util.urlAddParams('/app-api/customer/agreement/getByType', data);
		return http.get(url);
	},

	//通过手机号加密串获得用户角色门店列表
	getRolesByKey(data) {
		const url = util.urlAddParams('/app-api/system/dept/getUserDeptRoleList', data);
		return http.get(url);
	},

	//除了普通用户外的其他角色获取token
	getCommonToken(data) {
		return http.post('/app-api/system/auth/userRoleAuthToken', data);
	},
	//通过id获得图表数据
	getDataTu(data) {
		const url = util.urlAddParams('/app-api/customer/detect-chart/details', data);
		return http.get(url);
	},
	//报名
	Submit(data) {
		return http.post('/app-api/system/business-mini-users/add-mobile', data);
	}
};
